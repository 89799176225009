<template>
  <div>
    <v-card-title>
      <div class="mr-2 card-title--black3">Add recommendation</div>
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <v-select
          v-model="selected.turbineModDef"
          placeholder="Available mods"
          :items="turbineModDefs"
          class="mod-select"
          item-text="name"
          return-object
          solo
          dense
          hide-details
        ></v-select>
        <div
          v-if="Object.keys(selected.turbineModDef).length > 0"
          class="d-flex p align-center"
        >
          <vue-markdown
            :breaks="false"
            :anchor-attributes="anchorAttrs"
            :source="selected.turbineModDef.description"
            class="pl-2 mod-markdown"
          />
        </div>
      </div>
      <br v-if="selected.turbineModDef.requires_value" />
      <v-text-field
        v-if="selected.turbineModDef.requires_value"
        v-model="selected.value"
        solo
        dense
        :label="valueLabel"
        hide-details
        class="value-input"
      ></v-text-field>
      <br />
      <v-textarea v-model="selected.recommendationDetails" solo color="black">
        <template v-slot:label>
          <div>Recommendation details</div>
        </template>
      </v-textarea>
      <div class="d-flex">
        <v-btn color="primary" @click="toggleUploadDialog(true)">
          <img src="/img/file-upload.svg" class="upload-img mr-1" />
          Upload attachments
        </v-btn>
        <div v-if="selected.attachment"></div>
        <span
          v-for="(attachment, index) in selected.attachment"
          :key="index"
          class="d-flex align-center pl-2"
        >
          {{ attachment.name }}
          <v-icon @click="removeAttachment(attachment)">mdi-close</v-icon>
        </span>
        <v-spacer />
        <v-btn class="px-3 mr-1" outlined @click="cancel">Cancel</v-btn>
        <v-btn
          :loading="loading"
          color="green"
          class="white--text"
          @click="postRecommendation()"
          :disabled="!isFormComplete"
        >
          Save
        </v-btn>
      </div>

      <UploadDialog
        v-if="showDialog"
        :isNotSiteDocument="true"
        title="Upload attachment"
        @setShowDialog="toggleUploadDialog"
        @setFile="setFile"
      />
      <div
        @click="toggleUploadDialog(false)"
        class="backdrop"
        v-if="showDialog"
      ></div>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UploadDialog from "@/components/UploadDialog";
import VueMarkdown from "vue-markdown-v2";

export default {
  name: "RecommendationUploadWidget",
  components: {
    UploadDialog,
    VueMarkdown,
  },
  props: {},
  data() {
    return {
      showDialog: false,
      selected: {
        turbineModDef: {},
        recommendationDetails: "",
        attachment: null,
        value: "",
      },
      loading: false,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
    };
  },
  computed: {
    ...mapState({
      turbineModDefs: (state) => state.issueDetail.turbineModDefs,
      suggestedRecommendation: (state) =>
        state.issueDetail.suggestedRecommendation,
    }),
    isFormComplete() {
      if (this.selected.turbineModDef.requires_value) {
        return (
          Object.keys(this.selected.turbineModDef).length !== 0 &&
          this.selected.recommendationDetails !== "" &&
          this.selected.value !== ""
        );
      }

      return (
        Object.keys(this.selected.turbineModDef).length !== 0 &&
        this.selected.recommendationDetails !== ""
      );
    },
    valueLabel() {
      return this.selected.turbineModDef.value_units
        ? `Value (${this.selected.turbineModDef.value_units})`
        : "Value";
    },
  },
  methods: {
    ...mapActions({
      getTurbineModDefs: "issueDetail/getTurbineModDefs",
      updateIssueStatus: "issueDetail/updateIssueStatus",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      clickedCancel: "issueDetail/clickedCancel",
      getSuggestedRecommendation: "issueDetail/getSuggestedRecommendation",
    }),
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    setFile(chosenFile) {
      this.selected.attachment = chosenFile;
    },
    async postRecommendation() {
      let recommendationParams = {
        issueId: this.$route.params.issueId,
        statusDefId: 5, // id for 'pending implementation'
        attachment: this.selected.attachment,
        recommendation: this.selected.recommendationDetails,
        modId: this.selected.turbineModDef.id,
        kind: "status",
      };
      if (this.selected.turbineModDef.requires_value) {
        recommendationParams.value = +this.selected.value;
      }
      this.loading = true;
      await this.updateIssueStatus(recommendationParams);
      this.selected = {
        turbineModDef: {},
        recommendationDetails: "",
        attachment: null,
      };
      this.getIssueStatusData({ issueId: this.$route.params.issueId });
      this.$emit("updateWidgetCards");
      this.loading = false;
    },
    removeAttachment(attachment) {
      this.selected.attachment = this.selected.attachment.filter(
        (item) => item !== attachment,
      );
    },
    cancel() {
      this.selected = {
        turbineModDef: {},
        recommendationDetails: "",
        attachment: null,
        value: "",
      };
      this.clickedCancel(true);
    },
  },
  async beforeMount() {
    this.getTurbineModDefs();
    await this.getSuggestedRecommendation({
      issueId: this.$route.params.issueId,
    });
    if (this.suggestedRecommendation) {
      this.selected.recommendationDetails = this.suggestedRecommendation.notes;
      this.selected.turbineModDef =
        this.suggestedRecommendation.turbine_mod_def;
      this.selected.value = this.suggestedRecommendation.value;
    }
  },
};
</script>
<style lang="scss">
.mod-select,
.value-input {
  max-width: 360px;
}
.mod-markdown p {
  margin-bottom: 0;
}
</style>
