<template>
  <div class="recommendation-widget blue-background">
    <v-card-title>
      <div class="mr-2 card-title--black3">
        Recommendation:
        {{ issueStatus[0].turbine_recommendation.turbine_mod_definition.name }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">
            {{
              issueStatus[0].turbine_recommendation.turbine_mod_definition
                .description
            }}
          </vue-markdown>
          <br />
          <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
            issueStatus[0].turbine_recommendation.notes
          }}</vue-markdown>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="issueStatus[0].attachments.length > 0"
          class="d-flex align-center subtitle-2"
          >Attachments:
          <div
            v-for="(attachment, index) in issueStatus[0].attachments"
            :key="index"
          >
            <div class="pointer d-flex align-center">
              <a class="ml-1" :href="attachment.uri" download>{{
                attachment.name
              }}</a>
              <a :href="attachment.uri" download class="download-icon">
                <v-icon class="mr-1" size="25px">mdi-file-download</v-icon></a
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="8">
          <date-picker type="datetime" v-model="date" class="mr-2">
            <template v-slot:content="slotProps">
              <div class="d-flex">
                <calendar-panel
                  :disabled-date="(date) => date >= new Date()"
                  :value="slotProps.value"
                  @select="slotProps.emit"
                ></calendar-panel>
                <time-panel
                  :disabled-time="(date) => date >= new Date()"
                  show-time-header
                  :value="slotProps.value"
                  @select="slotProps.emit"
                ></time-panel>
              </div>
            </template>
          </date-picker>

          <v-select
            v-model="timeZone"
            :items="timeZones"
            class="timezone-select"
            item-text="name"
            item-value="value"
            label="Time zone"
            solo
            dense
            hide-details
          />
        </v-col>

        <v-col class="d-flex justify-end" cols="4">
          <div class="d-flex align-center">
            <div class="d-flex align-center">
              <v-btn
                color="green"
                class="px-4 mr-1 action-button white--text"
                :disabled="saveStatusLoading || !dateTimeSelected"
                @click="saveIssueStatus()"
              >
                <CSpinner size="sm" v-if="saveStatusLoading" class="mr-2" />
                <v-icon dense class="pr-2" color="white">mdi-check</v-icon>
                Action implemented
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import VueMarkdown from "vue-markdown-v2";
import timeZones from "@/helpers/timezones";
import { DateTime } from "luxon";

const { CalendarPanel, TimePanel } = DatePicker;

export default {
  name: "RecommendationWidget",
  components: {
    DatePicker,
    VueMarkdown,
    CalendarPanel,
    TimePanel,
  },
  props: {
    issueStatus: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      saveStatusLoading: false,
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      date: null,
      timeZones,
      timeZone: null,
    };
  },
  computed: {
    dateTimeSelected() {
      return this.timeZone !== null && this.date !== null;
    },
  },
  methods: {
    ...mapActions({
      updateIssueStatus: "issueDetail/updateIssueStatus",
      getIssueStatusData: "issueDetail/getIssueStatusData",
    }),
    async saveIssueStatus() {
      this.saveStatusLoading = true;
      let updateObj = {};

      // Set timezone of selected time without converting time to new timezone offset
      const selectedTime = DateTime.fromJSDate(this.date);
      var rezoned = selectedTime.setZone(this.timeZone, {
        keepLocalTime: true,
      });
      // Then convert the rezoned time to UTC
      const utc = rezoned.setZone("UTC");

      if (this.date) {
        updateObj = {
          issueId: this.$route.params.issueId,
          statusDefId: 6, // Implemented, pending verification
          ts: utc.toFormat("yyyy-LL-dd HH:mm:ss"),
          mod: this.issueStatus[0].turbine_recommendation
            .turbine_mod_definition,
          turbineRecommendationId:
            this.issueStatus[0].turbine_recommendation_id,
        };
      }

      await this.updateIssueStatus(updateObj);
      this.getIssueStatusData({ issueId: this.$route.params.issueId });
      this.$emit("updateWidgetCards");
      this.saveStatusLoading = false;
    },
  },
};
</script>

<style lang="scss">
.recommendation-widget {
  li {
    margin-left: 20px;
  }
  .mx-input {
    height: 38px;
  }
  .mx-datepicker {
    cursor: pointer;
  }
  .v-select__selection {
    font-size: 14px;
  }
  .action-button {
    font-weight: 600;
  }

  .mx-datepicker,
  .timezone-select {
    max-width: 250px;
  }
  .download-icon {
    text-decoration: none;

    i:hover {
      color: var(--v-primary-base);
    }
  }
}
.download-obj {
  height: 0.1rem;
  width: 0.1rem;
  background: rgba(0, 0, 0, 0);
  border: none;
}
</style>
