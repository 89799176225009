<template>
  <div>
    <v-expansion-panels class="loss-model">
      <KeepAlive>
        <v-expansion-panel
          @change="handleClick"
          data-cy="loss-model-expansion-panel"
        >
          <v-expansion-panel-header>
            <div class="card-title--black3">Loss model</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-center align-center">
              <DataLoading v-if="lossesGainsLoading" />
            </div>
            <div v-if="!lossesGainsLoading && lossesGains">
              <v-row>
                <v-col cols="4">
                  <div
                    v-if="lossesGains"
                    class="d-flex flex-column"
                    :class="{ 'pb-4': plotlyData.length === 0 }"
                  >
                    <p v-if="lossesGains.loss_model_type" class="mb-3">
                      <span class="label">Loss model type:</span>
                      {{ lossesGains.loss_model_type }}
                    </p>
                    <p v-if="lossesGains.loss_model_desc" class="mb-3">
                      <span class="label">Loss model description:</span>
                      {{ lossesGains.loss_model_desc }}
                    </p>
                    <p v-if="lossesGains.loss_model_details" class="mb-3">
                      <span class="label mr-1">Loss model details:</span>
                      <vue-mathjax
                        ref="mathjaxContainer"
                        :safe="false"
                        :formula="formula"
                        :options="mathjaxOptions"
                      >
                      </vue-mathjax>
                    </p>
                    <p v-if="lossesGains.loss_data_start_ts" class="mb-3">
                      <span class="label">Loss data start:</span>
                      {{ lossesGains.loss_data_start_ts }}
                    </p>
                    <p v-if="lossesGains.loss_data_end_ts" class="mb-3">
                      <span class="label">Loss data end:</span>
                      {{ lossesGains.loss_data_end_ts }}
                    </p>
                    <p
                      v-if="lossesGains.loss_period_total_energy_mwh"
                      class="mb-3"
                    >
                      <span class="label">Total energy in period:</span>
                      {{
                        `${roundToString(
                          lossesGains.loss_period_total_energy_mwh,
                          -1,
                        )} MWh`
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="8">
                  <Plotly
                    v-if="plotlyData.length !== 0"
                    id="plotly_diagram"
                    :data="plotlyData"
                    :layout="layout"
                    :responsive="true"
                    :display-mode-bar="false"
                    :showTips="false"
                  ></Plotly>

                  <Plotly
                    v-if="
                      lossesGains &&
                      lossesGains.loss_model_plot &&
                      Object.keys(lossesGains.loss_model_plot) !== 0
                    "
                    id="plotly_diagram2"
                    :data="lossesGains.loss_model_plot.data"
                    :layout="lossModelLayout"
                    :responsive="true"
                    :display-mode-bar="false"
                    :showTips="false"
                  ></Plotly>
                  <vue-mathjax
                    v-if="
                      lossesGains && lossesGains.loss_model_plot_description
                    "
                    :formula="lossesGains.loss_model_plot_description"
                    :options="mathjaxOptions"
                    class="mb-4"
                  ></vue-mathjax>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </KeepAlive>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DataLoading from "@/components/DataLoading";
import Plotly from "@/components/Plotly";
import { roundToString, processMarkdown } from "@/helpers/functions";
import { VueMathjax } from "vue-mathjax";

export default {
  name: "LossModel",
  components: {
    DataLoading,
    Plotly,
    "vue-mathjax": VueMathjax,
  },
  data() {
    return {
      isOpen: false,
      layout: {
        autosize: true,
        height: 250,
        margin: {
          t: 25,
        },
        legend: {
          orientation: "h",
          y: -0.25,
          traceorder: "normal",
          font: {
            size: 12,
          },
          bordercolor: "#FFFFFF",
          borderwidth: 1,
        },
        plot_bgcolor: "#E5ECF6",
        paper_bgcolor: this.$vuetify.theme.isDark ? "#1e1e1e" : "#fff",
        font: { color: this.$vuetify.theme.isDark ? "#c9d1d9" : "#242426" },
        xaxis: {
          title: {
            text: "Date",
          },
          gridcolor: "white",
          zerolinecolor: "white",
        },
        yaxis: {
          title: {
            text: "Daily energy loss (KWh)",
          },
          gridcolor: "white",
          zerolinecolor: "white",
        },
        yaxis2: {
          title: "Daily energy production (KWh)",
          overlaying: "y",
          side: "right",
        },
      },
      plotlyData: [],
      roundToString,
      processMarkdown,
      mathjaxOptions: {
        tex2jax: {
          inlineMath: [["$", "$"]],
        },
      },
    };
  },
  props: {
    issueId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapState({
      lossesGains: (state) => state.issueDetail.lossesGains,
      lossesGainsLoading: (state) => state.issueDetail.loading.getLossesGains,
      lossModelDetailsHTML: (state) => state.issueDetail.lossModelDetailsHTML,
    }),
    lossModelLayout() {
      // eslint-disable-next-line no-undef
      const layout = structuredClone(this.lossesGains.loss_model_plot.layout);
      layout.autosize = true;
      layout.height = 250;
      layout.margin = {
        t: 25,
      };
      layout.legend = {
        orientation: "h",
        y: -0.25,
        traceorder: "normal",
        font: {
          size: 12,
        },
        bordercolor: "#FFFFFF",
        borderwidth: 1,
      };
      if (this.$vuetify.theme.isDark) {
        layout.paper_bgcolor = "#1e1e1e";
        layout.font = {
          color: "#c9d1d9",
        };
      }

      return layout;
    },
    formula() {
      if (this.lossModelDetailsHTML) {
        return this.lossModelDetailsHTML;
      } else if (this.lossesGains?.loss_model_details) {
        return this.lossesGains.loss_model_details;
      } else {
        return {};
      }
    },
  },
  methods: {
    ...mapActions({
      getLossesGains: "issueDetail/getLossesGains",
    }),
    async handleClick() {
      this.isOpen = !this.isOpen;
      if (this.isOpen && this.plotlyData.length === 0) {
        await this.getLossesGains({
          issueId: this.issueId,
          returnData: true,
          plot: true,
        });
        this.setPlotlyData();
      }
    },
    setPlotlyData() {
      if (this.lossesGains?.loss_data) {
        this.plotlyData = [
          {
            x: this.lossesGains.loss_data.map((d) => d.ts),
            y: this.lossesGains.loss_data.map((d) => d.energy_diff_kwh),
            name: "Loss",
          },
          {
            x: this.lossesGains.loss_data.map((d) => d.ts),
            y: this.lossesGains.loss_data.map((d) => d.total_energy_kwh),
            name: "Production",
            yaxis: "y2",
          },
        ];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-expansion-panel-content__wrap {
  padding: 0 16px;
}
.loss-model {
  p {
    line-height: 1.65;
    color: var(--v-text-base);
  }
  span {
    color: var(--v-text-base);
  }
}
.label {
  font-weight: 500;
  color: var(--v-text-base) !important;
}
</style>
