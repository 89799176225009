<template>
  <div class="w100">
    <span v-if="data.status === 'create'"
      ><span class="commenter-name font-weight-light-bold">{{ name }}</span
      >created issue on {{ issueStatusDate }}</span
    >
    <span v-else class="d-flex flex-wrap align-center">
      <span class="commenter-name font-weight-light-bold mr-1">{{ name }}</span>
      changed issue status to
      <b
        class="text-uppercase text-status ml-1 mr-1"
        v-bind:style="data.status | statusStyle"
        >{{ data.status }}</b
      >
      on {{ issueStatusDate }}
      <PopUp :content="[data.statusComment]" class="status-popup">
        <template v-slot:hover>
          <v-icon v-if="data.statusComment" color="gray"
            >mdi-message-text-outline</v-icon
          >
        </template>
        <template v-slot:content>
          {{ data.statusComment }}
        </template>
      </PopUp>
    </span>
  </div>
</template>

<script>
import PopUp from "@/components/PopUp";
import { statusColors } from "../helpers/variables";
import dayjs from "dayjs";

export default {
  name: "CommenterStatus",
  components: { PopUp },
  data() {
    return {
      statusColors,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    issueStatusDate() {
      return dayjs(this.data.date).format("YYYY-MM-DD HH:mm:ss") + " (UTC)";
    },
  },
  filters: {
    statusLabel(val) {
      switch (val) {
        case "awaiting":
          return "awaiting recommendation";
        case "pending":
          return "pending implementation";

        default:
          return val;
      }
    },
    statusStyle(val) {
      let backgroundColor = "";
      let color = "";

      switch (val) {
        case "New":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "In progress":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Dismissed":
          backgroundColor = statusColors.red;
          color = "#242426";
          break;
        case "Not visible":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Unconfirmed (not visible)":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Awaiting recommendation":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Deferred":
          backgroundColor = statusColors.gray;
          color = "gray";
          break;
        case "Invalidated":
          backgroundColor = statusColors.red;
          color = "#242426";
          break;
        case "Actionable, awaiting recommendation":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Waiting for good mod window":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Implemented, pending verification":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Implemented":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Pending implementation":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Pending verification":
          backgroundColor = statusColors.yellow;
          color = "#242426";
          break;
        case "Fixed":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Fixed + measured":
          backgroundColor = statusColors.green;
          color = "#242426";
          break;
        case "Superseded":
          backgroundColor = statusColors.green;
          color = "gray";
          break;
        default:
          break;
      }

      return {
        backgroundColor,
        color,
      };
    },
  },
};
</script>

<style lang="scss">
.text-status {
  border-radius: 1rem;
  padding: 2px 8px;
  white-space: nowrap;
}
.status-popup.popup-container .popup {
  width: max-content;
  max-width: 500px;
}
.status-popup .popup-slot {
  display: flex;
}
</style>
