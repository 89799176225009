<template>
  <DataLoading v-if="pageLoading" />
  <div class="issue-detail" v-else>
    <div
      class="issue-detail__header d-flex align-center justify-space-between mb-3"
    >
      <div class="d-flex flex-wrap">
        <h3 class="my-3 d-flex align-center">
          <span
            class="mr-3 main-title"
            v-if="issueData && 'definition' in issueData"
          >
            {{ issueData.definition.name }}{{ valueWithUnits }}
          </span>
        </h3>
      </div>
      <div
        v-if="issueData && 'definition' in issueData"
        class="issue-detail__header__help d-flex align-center justify-space-between"
        @click="handleHelpClick"
      >
        <v-icon>{{ informationIcon }}</v-icon>
        <div class="ml-1">{{ issueData.definition.name || "N/A" }}</div>
      </div>
    </div>

    <div class="mb-6">
      <v-row>
        <v-col
          v-for="(widget, index) in widgetCards"
          :key="index + '_widget'"
          class="widget-col summary-cards-col"
          :class="{
            'third-card-no-padding': hasThreeWidgetCards && index == 2,
          }"
          sm="6"
          :lg="widgetCards.length === 3 ? 4 : 3"
        >
          <SummaryCard
            :headerContent="widget.headerContent"
            :bodyContent="widget.bodyContent"
            :bodyPercent="widget.bodyPercent"
            :footerContent="widget.footerContent"
            :footerPercent="widget.footerPercent"
            :popUpData="widget.popUpData"
            :class="{ 'total-gain': widget.headerContent === 'AEP gain' }"
          />
        </v-col>
      </v-row>
    </div>

    <div class="mb-6">
      <v-card class="recommendation-upload-widget blue-background">
        <div
          class="d-flex justify-center align-center"
          v-if="loading.getIssueStatusData"
        >
          <DataLoading />
        </div>
        <div v-else>
          <IssueStatusWidget
            v-if="
              (latestStatus.definition_name_external !==
                'Awaiting recommendation' &&
                latestStatus.definition_name_external !==
                  'Pending implementation' &&
                latestStatus.definition_name_external !==
                  'Actionable, awaiting recommendation') ||
              (latestStatus.definition_name_external ===
                'Actionable, awaiting recommendation' &&
                !userHasInternalWriteAccess)
            "
            :userHasWriteAccess="userHasWriteAccess"
            :userHasInternalAccess="userHasInternalAccess"
            :lossPct="lossPct"
            @updateStatus="updateStatusHistory"
            @updateWidgetCards="setWidgetCards"
            @statusSelected="handleStatusSelected"
          />
          <RecommendationUploadWidget
            v-if="
              userHasInternalWriteAccess &&
              (latestStatus.definition_name_external ===
                'Awaiting recommendation' ||
                latestStatus.definition_name_external ===
                  'Actionable, awaiting recommendation' ||
                recommendationSelection === 'New recommendation')
            "
            @updateStatus="updateStatusHistory"
            @updateWidgetCards="setWidgetCards"
          />
          <RecommendationWidget
            v-if="
              latestStatus.definition_name_external === 'Pending implementation'
            "
            :issueStatus="issueStatus"
            @updateStatus="updateStatusHistory"
            @updateWidgetCards="setWidgetCards"
          />
        </div>
      </v-card>
    </div>
    <div class="mb-6">
      <SuggestedRecommendations
        :open="
          latestStatus.definition_id === 1 ||
          latestStatus.definition_id === 2 ||
          latestStatus.definition_id === 3
            ? 0
            : 1
        "
      />
    </div>
    <div class="mb-6">
      <DetailsWidget
        :editIssueDetails="editIssueDetails"
        :issueDetailsTitle="issueDetailsTitle"
        :userHasInternalWriteAccess="userHasInternalWriteAccess"
        :editTurbineIssueDetails="editTurbineIssueDetails"
        :editDetailsLoading="editDetailsLoading"
        :issueDataDetails="issueDataDetails"
        :sendIssueDetailsEdit="sendIssueDetailsEdit"
        :cancelEditIssueDetails="cancelEditIssueDetails"
        :supersededIssues="supersededIssues"
      />
    </div>

    <div class="mb-6">
      <LossModel :issueId="$route.params.issueId" />
    </div>

    <div class="mb-6 icon-anchor">
      <v-card v-bind:class="{ 'blue-background': isEditEvidence }">
        <div v-if="evidenceLoading" class="spinner-container">
          <div class="d-flex align-center justify-center pt-2">
            <v-progress-circular
              :size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
        <v-card-text v-else>
          <div class="d-flex">
            <div class="d-flex">
              <div class="mr-2 card-title--black3">
                <div v-if="isEditEvidence">Editing evidence</div>
                <div v-else>
                  Evidence
                  <span class="evidence-index"
                    >({{ evidenceIndex + 1 }}/{{
                      initialEvidences.length
                    }})</span
                  >
                </div>
              </div>
              <div class="mt-1" v-if="isPlotlyFigure && !isEditEvidence">
                <p>
                  <i
                    >Click and drag to zoom in to a selection on the plot.
                    Double click to reset.</i
                  >
                </p>
              </div>
            </div>
            <div class="d-flex align-start mb-3 edit-evidence__icon">
              <div class="d-flex ml-4 mr-2" v-if="!isEditEvidence">
                <span class="mr-2 label">Snapshot</span>
                <v-switch
                  v-model="showCurrentEvidence"
                  class="ma-0 pa-0"
                  :disabled="isCurrentSwitchDisabled"
                  @change="handleChangeCurrentSwitch"
                ></v-switch>
                <span class="label">Current</span>
              </div>
              <div class="pointer mr-1">
                <v-tooltip top :open-delay="popupDelay" v-if="!isEditEvidence">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="1.25rem"
                      class="info-icon"
                      >{{ informationIcon }}</v-icon
                    >
                  </template>
                  <span
                    >Snapshot evidence is that which was created when the issue
                    was detected. Current evidence reflects the new data added
                    to the system since then.</span
                  >
                </v-tooltip>
              </div>
              <div v-if="!isEditEvidence">
                <v-tooltip bottom open-delay="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1 pointer"
                      data-ga="issue_detail_evidence_full_chart_true"
                      @click="seeFullChart()"
                      >mdi-arrow-expand-all</v-icon
                    >
                  </template>
                  <span>See full chart</span>
                </v-tooltip>
              </div>
              <div v-if="userHasInternalWriteAccess && !isEditEvidence">
                <v-tooltip bottom open-delay="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      size="19.5px"
                      class="pointer"
                      @click.stop="editEvidence"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Edit evidence</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <EditEvidenceWidget
            v-if="isEditEvidence"
            :isEdit="isEditEvidence"
            :evidenceItem="initialEvidences[evidenceIndex]"
            :evidenceIndex="evidenceIndex"
            @saveEditedEvidence="saveEditedEvidence"
            @cancelEdit="cancelEditEvidence"
          />
          <div v-else>
            <!-- Main evidence window -->
            <ChartWidget
              v-if="initialEvidences.length > 0"
              :data="
                initialEvidences[evidenceIndex].current
                  ? initialEvidences[evidenceIndex].current.show
                    ? initialEvidences[evidenceIndex].current
                    : initialEvidences[evidenceIndex].snapshot
                  : initialEvidences[evidenceIndex].snapshot
              "
              :turbineName="issueData.turbine.shortname"
            />
            <div class="v-window__prev">
              <button
                type="button"
                class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                aria-label="Previous visual"
                @click="previous"
              >
                <span class="v-btn__content"
                  ><i
                    aria-hidden="true"
                    data-ga="issue_detail_evidence_carousel_previous"
                    class="v-icon notranslate mdi mdi-chevron-left theme--dark"
                    style="font-size: 36px"
                  ></i
                ></span>
              </button>
            </div>
            <div class="v-window__next">
              <button
                type="button"
                class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                aria-label="Next visual"
                @click="next"
              >
                <span class="v-btn__content"
                  ><i
                    aria-hidden="true"
                    data-ga="issue_detail_evidence_carousel_next"
                    class="v-icon notranslate mdi mdi-chevron-right theme--dark"
                    style="font-size: 36px"
                  ></i
                ></span>
              </button>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <div class="mb-6">
      <!-- Comment editor -->
      <IssueCommentWidget
        :issueId="$route.params.issueId"
        :userHasWriteAccess="userHasWriteAccess"
        @postComment="refreshComments"
      />
    </div>
    <!-- Main evidence full chart -->
    <v-dialog
      v-model="dialog"
      :disabled="initialEvidences.length === 0"
      max-width="1140px"
      width="100%"
      hide-overlay
    >
      <v-card>
        <div
          class="d-flex align-center justify-center pt-6"
          v-if="initialEvidences.length === 0"
        >
          <v-progress-circular
            :size="45"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-card-title
          style="border-bottom: 1px solid; border-color: #d8dbe0"
          class="d-flex justify-space-between"
          v-else
        >
          <div class="d-flex align-center cmodal-title">
            <div>
              {{ title }}
            </div>
            <div v-if="isInternalEvidence" class="internal-badge">INTERNAL</div>
          </div>
          <div class="d-flex align-center">
            <v-tooltip top :open-delay="popupDelay">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex ml-4 mr-2 align-center"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!isEditEvidence"
                >
                  <span class="mr-2 label modal-label">Snapshot</span>
                  <v-switch
                    v-model="showCurrentEvidence"
                    dense
                    :disabled="isCurrentSwitchDisabled"
                    @change="handleChangeCurrentSwitch"
                    class="ma-0"
                    hide-details
                  ></v-switch>
                  <span class="label modal-label">Current</span>
                </div>
              </template>
              <span
                >Snapshot evidence is that which was created when the issue was
                detected. Current evidence reflects the new data added to the
                system since then.</span
              >
            </v-tooltip>
            <div class="pointer pb-1">
              <v-tooltip top :open-delay="popupDelay" v-if="!isEditEvidence">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="1.25rem"
                    class="info-icon"
                    >{{ informationIcon }}</v-icon
                  >
                </template>
                <span
                  >Snapshot evidence is that which was created when the issue
                  was detected. Current evidence reflects the new data added to
                  the system since then.</span
                >
              </v-tooltip>
            </div>
            <v-btn icon @click="closeDialog">
              <v-icon data-ga="issue_detail_evidence_full_chart_false"
                >mdi-close</v-icon
              >
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="mt-4">
          <EvidenceViewModal
            v-if="initialEvidences.length > 0"
            :evidence-index="evidenceIndex"
            :evidence="initialEvidences[evidenceIndex]"
            :evidences="initialEvidences"
            page="issue_detail"
            :showCurrentEvidence="
              initialEvidences[evidenceIndex].current
                ? initialEvidences[evidenceIndex].current.show
                  ? true
                  : false
                : false
            "
            @updateTitle="updateTitle"
            @clickPrevious="previous"
            @clickNext="next"
        /></v-card-text>
      </v-card>
    </v-dialog>
    <div
      @click="closeDialog"
      class="backdrop"
      data-ga="issue_detail_evidence_full_chart_false"
      v-if="dialog"
    ></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getPlotlyData } from "@/services/issueService";
import SummaryCard from "@/components/SummaryCards/SummaryCard";
import ChartWidget from "../components/ChartWidget";
import IssueStatusWidget from "../components/IssueStatusWidget";
import RecommendationUploadWidget from "../components/RecommendationUploadWidget";
import RecommendationWidget from "../components/RecommendationWidget";
import IssueCommentWidget from "../components/IssueCommentWidget";
import { informationIcon, popupDelay } from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import EvidenceViewModal from "@/components/EvidenceViewModal";
import DataLoading from "@/components/DataLoading";
import EditEvidenceWidget from "@/components/EditEvidenceWidget";
import LossModel from "@/components/LossModel";
import DetailsWidget from "@/components/DetailsWidget";
import SuggestedRecommendations from "@/components/SuggestedRecommendations";

export default {
  name: "IssueDetail",
  components: {
    SummaryCard,
    ChartWidget,
    IssueStatusWidget,
    IssueCommentWidget,
    EvidenceViewModal,
    DataLoading,
    RecommendationWidget,
    RecommendationUploadWidget,
    EditEvidenceWidget,
    LossModel,
    DetailsWidget,
    SuggestedRecommendations,
  },
  data() {
    return {
      isOpen: false,
      openedPanel: null,
      widgetCards: [],
      dialog: false,
      selectedEvidence: 0,
      selectedSubscription: null,
      evidences: [],
      menu: false,
      modal: false,
      menu2: false,
      columns: 1,
      evidenceLoading: false,
      evidenceIndex: 0,
      editIssueDetails: false,
      issueDetailsTitle: "Details",
      isEditEvidence: false,
      title: null,
      selectedSupersededIssue: {},
      informationIcon,
      popupDelay,
      showCurrentEvidence: false,
      lossesGainsLoading: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.issueDetail.loading,
      apiError: (state) => state.issueDetail.error,
      issueData: (state) => state.issueDetail.issueData,
      issueStatuses: (state) => state.issueDetail.issueStatuses,
      lossesGains: (state) => state.issueDetail.lossesGains,
      isNotificationNew: (state) => state.notifications.isNotificationNew,
      latestStatus: (state) => state.issueDetail.latestStatus,
      recommendationSelection: (state) =>
        state.issueDetail.recommendationSelection,
      editDetailsLoading: (state) =>
        state.issueDetail.loading.editTurbineIssueDetails,
      editEvidenceResponse: (state) => state.issueDetail.editEvidenceResponse,
      combinedEvidences: (state) => state.evidence.combinedEvidences,
      staticCombinedEvidences: (state) =>
        state.evidence.staticCombinedEvidences,
      userData: (state) => state.user.userData,
    }),
    userHasWriteAccess() {
      return this.issueData?.user_has_write_access;
    },
    userHasInternalAccess() {
      return this.issueData?.user_has_internal_access;
    },
    userHasInternalWriteAccess() {
      return this.userHasWriteAccess && this.userHasInternalAccess;
    },
    issueStatus() {
      return this.issueStatuses.filter(
        (issue) => issue.turbine_issue_id === +this.$route.params.issueId,
      );
    },
    errorWatcher() {
      if (this.apiError) {
        return this.apiError;
      }

      if (Object.keys(this.issueData || {}).length === 0) {
        return "There is no data";
      }

      return "";
    },
    valueWithUnits() {
      let value = null;
      if (this.issueData.value) {
        value = ` (${this.issueData.value.toFixed(1)}`;
        if (this.issueData.definition.units) {
          if (this.issueData.definition.units === "degrees") {
            value = ` (${roundToString(this.issueData.value, 0)}`;
          }
          value += ` ${this.issueData.definition.units}`;
        }
        value += ")";
      }
      return value;
    },
    aepLossMWh() {
      if (
        this.lossesGains?.aep_loss_mwhpyr &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(+this.lossesGains.aep_loss_mwhpyr, 0);
      } else {
        return 0.0;
      }
    },
    aepGainMWh() {
      if (
        this.lossesGains?.aep_gain_mwhpyr &&
        this.aepGainPct !== 0.0 &&
        this.aepGainPct !== "0.0"
      ) {
        return roundToString(+this.lossesGains.aep_gain_mwhpyr, -1);
      } else {
        return 0.0;
      }
    },
    aepLossUSD() {
      if (
        this.lossesGains?.aep_loss_upper_usdpyr &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.aep_loss_upper_usdpyr, -1);
      } else {
        return 0;
      }
    },
    aepGainUSD() {
      if (
        this.lossesGains?.aep_gain_upper_usdpyr &&
        this.aepGainPct !== 0.0 &&
        this.aepGainPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.aep_gain_upper_usdpyr, -1);
      } else {
        return 0;
      }
    },
    aepLossPct() {
      if (this.lossesGains?.aep_loss_pct) {
        return roundToString(+this.lossesGains.aep_loss_pct, 1);
      } else {
        return "0.0";
      }
    },
    aepGainPct() {
      if (this.lossesGains?.aep_gain_pct) {
        return roundToString(+this.lossesGains.aep_gain_pct, 1);
      } else {
        return "0.0";
      }
    },
    lossUSD() {
      if (
        this.lossesGains?.loss_upper_usd &&
        this.aepLossPct !== 0.0 &&
        this.aepLossPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.loss_upper_usd, -1);
      } else {
        return 0;
      }
    },
    gainUSD() {
      if (
        this.lossesGains?.gain_upper_usd &&
        this.aepGainPct !== 0.0 &&
        this.aepGainPct !== "0.0"
      ) {
        return roundToString(this.lossesGains.gain_upper_usd, -1);
      } else {
        return 0;
      }
    },
    lossMWh() {
      if (
        this.lossesGains &&
        (this.lossesGains.loss_mwh || this.lossesGains.loss_mwh === 0)
      ) {
        if (this.aepLossPct === 0.0 || this.aepLossPct === "0.0") {
          return 0;
        } else {
          return roundToString(+this.lossesGains.loss_mwh, 0);
        }
      } else {
        return "TBD";
      }
    },
    gainMWh() {
      if (
        this.lossesGains &&
        (this.lossesGains.gain_mwh || this.lossesGains.gain_mwh === 0)
      ) {
        if (this.aepGainPct === 0.0 || this.aepGainPct === "0.0") {
          return 0;
        } else {
          return roundToString(this.lossesGains.gain_mwh, -1);
        }
      } else {
        return "TBD";
      }
    }, // if (this.lossPct === "TBD") { show alternate status display}
    lossPct() {
      if (
        this.lossesGains &&
        (this.lossesGains.loss_pct || this.lossesGains.loss_pct === 0)
      ) {
        if (this.aepLossPct === 0.0 || this.aepLossPct === "0.0") {
          return "0.0";
        } else {
          return "-" + roundToString(this.lossesGains.loss_pct, 1);
        }
      } else {
        return "TBD";
      }
    },
    gainPct() {
      if (
        this.lossesGains &&
        (this.lossesGains.gain_pct || this.lossesGains.gain_pct === 0)
      ) {
        if (this.aepGainPct === 0.0 || this.aepGainPct === "0.0") {
          return "0.0";
        } else {
          return roundToString(this.lossesGains.gain_pct, 1);
        }
      } else {
        return "TBD";
      }
    },
    /**
     * Returns the timestamp from a resolved or fixed issue status
     */
    effectiveDate() {
      if (this.latestStatus?.ts) {
        return `Since ${this.latestStatus.ts} (UTC)`;
      } else {
        return "";
      }
    },
    startTs() {
      if (this.lossesGains && Object.keys(this.lossesGains).length > 0) {
        return this.lossesGains.issue_start_ts + " (UTC)";
      } else {
        return "Unknown";
      }
    },
    issueDataDetails() {
      return this.issueData?.details;
    },
    isPlotlyFigure() {
      if (
        this.evidenceIndex >= 0 &&
        this.showCurrentEvidence &&
        this.initialEvidences.length > 0
      ) {
        for (const evidence of this.initialEvidences) {
          if (
            this.initialEvidences.indexOf(evidence) === this.evidenceIndex &&
            evidence.current?.type.includes("plotly")
          ) {
            return true;
          }
        }
      } else if (
        this.evidenceIndex >= 0 &&
        !this.showCurrentEvidence &&
        this.initialEvidences.length > 0
      ) {
        for (const evidence of this.initialEvidences) {
          if (
            this.initialEvidences.indexOf(evidence) === this.evidenceIndex &&
            evidence.snapshot?.type.includes("plotly")
          ) {
            return true;
          }
        }
      }

      return false;
    },
    isCurrentSwitchDisabled() {
      if (
        this.initialEvidences?.length > 0 &&
        this.initialEvidences[this.evidenceIndex]?.current?.hasMatch &&
        this.initialEvidences[this.evidenceIndex]?.snapshot?.hasMatch
      ) {
        return false;
      } else {
        return true;
      }
    },
    supersededIssues() {
      if (this.issueData?.superseded_turbine_issues.length > 0) {
        return this.issueData.superseded_turbine_issues;
      } else {
        return [];
      }
    },
    isInternalEvidence() {
      if (
        this.initialEvidences.length > 0 &&
        this.initialEvidences[this.evidenceIndex]
      ) {
        if (this.initialEvidences[this.evidenceIndex]?.current?.show) {
          return this.initialEvidences[this.evidenceIndex]?.current?.internal;
        } else {
          return this.initialEvidences[this.evidenceIndex]?.snapshot?.internal;
        }
      } else {
        return false;
      }
    },
    initialEvidences() {
      const turbineIssueId = +this.$route.params.issueId;
      if (
        this.staticCombinedEvidences.length > 0 &&
        this.staticCombinedEvidences.length === this.combinedEvidences.length &&
        this.staticCombinedEvidences[0].snapshot.turbine_issue_id ===
          turbineIssueId
      ) {
        return this.staticCombinedEvidences;
      } else if (this.combinedEvidences.length > 0) {
        return this.combinedEvidences;
      } else {
        return [];
      }
    },
    hasThreeWidgetCards() {
      return this.widgetCards.length === 3;
    },
    okToSetWidgetCards() {
      return (
        this.issueData &&
        Object.keys(this.issueData).length > 0 &&
        !this.lossesGainsLoading &&
        this.latestStatus &&
        Object.keys(this.latestStatus).length > 0
      );
    },
    pageLoading() {
      if (
        this.loading.fetchIssueDetailData ||
        this.loading.issueStatusData ||
        this.lossesGainsLoading
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchIssueDetailData: "issueDetail/fetchIssueDetailData",
      getIssueStatusData: "issueDetail/getIssueStatusData",
      getLossesGains: "issueDetail/getLossesGains",
      getConfirmedIssues: "issues/getIssues",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getComments: "comments/getComments",
      updateIssueDetails: "issueDetail/editTurbineIssueDetails",
      editIssueEvidence: "issueDetail/editIssueEvidence",
      getCurrentEvidence: "evidence/getCurrentEvidence",
      setStaticCombinedEvidence: "evidence/setStaticCombinedEvidence",
      clearStaticCombinedEvidence: "evidence/clearStaticCombinedEvidence",
    }),
    setDataByUrl(mode) {
      const url = new URL(window.location.href);
      let evidenceParam = null;
      let evidenceZoomed = null;
      let evidenceView = null;
      // Check for initial load
      if (this.issueData?.evidence.length > 0 && mode === "before_mount") {
        if (url.searchParams.get("evidence")) {
          evidenceParam = Number(url.searchParams.get("evidence"));
        }
        if (url.search.includes("evidence_zoomed")) {
          evidenceZoomed = Number(url.searchParams.get("evidence_zoomed"));
        }
        if (url.search.includes("evidence_view")) {
          evidenceView = url.searchParams.get("evidence_view");
        }

        if (evidenceParam) {
          url.search = "";
          url.searchParams.set("evidence", evidenceParam);
          this.evidenceIndex = evidenceParam - 1;
          if (evidenceView === "current") {
            this.showCurrentEvidence = true;
            url.searchParams.set("evidence_view", "current");
          } else {
            this.showCurrentEvidence = false;
            url.searchParams.set("evidence_view", "snapshot");
          }
          if (evidenceZoomed) {
            url.searchParams.set("evidence_zoomed", evidenceZoomed);
            this.seeFullChart();
          }
        } else {
          url.search = "";
          url.searchParams.set("evidence", 1);
          url.searchParams.set("evidence_view", "snapshot");
        }
      } else if (this.issueData?.evidence.length > 0) {
        url.search = "";
        url.searchParams.set("evidence", this.evidenceIndex + 1);
        const type = this.initialEvidences[this.evidenceIndex].current?.show
          ? "current"
          : "snapshot";
        url.searchParams.set("evidence_view", type);
        url.searchParams.set("evidence_zoomed", this.dialog ? 1 : 0);
      } else {
        url.search = "";
      }

      history.replaceState(null, null, url);
    },
    previous() {
      this.showCurrentEvidence = false;
      if (this.evidenceIndex > 0) {
        this.evidenceIndex--;
      } else {
        this.evidenceIndex = this.initialEvidences.length - 1;
      }
      if (this.initialEvidences[this.evidenceIndex]) {
        this.showCurrentEvidence =
          this.initialEvidences[this.evidenceIndex].isCurrent;
      }
      // Setting evidence url param
      this.setDataByUrl();
    },
    next() {
      this.showLiveEvidence = false;
      if (this.evidenceIndex < this.initialEvidences.length - 1) {
        this.evidenceIndex++;
      } else {
        this.evidenceIndex = 0;
      }
      if (this.initialEvidences[this.evidenceIndex]) {
        this.showLiveEvidence =
          this.initialEvidences[this.evidenceIndex].isCurrent;
      }
      // Setting evidence url param
      this.setDataByUrl();
    },
    seeFullChart() {
      const url = new URL(window.location.href);
      const zoomed = +url.searchParams.get("evidence_zoomed");
      this.dialog = true;
      if (!zoomed) {
        url.searchParams.set("evidence_zoomed", 1);
        history.replaceState(null, null, url);
      }
    },
    closeDialog() {
      const url = new URL(window.location.href);
      url.searchParams.set("evidence_zoomed", 0);
      url.searchParams.set("evidence", this.evidenceIndex + 1);
      this.dialog = false;

      history.replaceState(null, null, url);
    },
    setWidgetCards() {
      if (this.issueData && Object.keys(this.issueData).length > 0) {
        let widgetCards = [
          {
            headerContent: "Site/turbine",
            bodyContent:
              `${this.issueData.turbine.farm.name} turbine ${this.issueData.turbine.shortname}` ||
              "N/A",
            footerContent: `${this.issueData.turbine.turbine_model.manufacturer.name} ${this.issueData.turbine.turbine_model.name}`,
          },
          {
            headerContent: "Cumulative losses",
            bodyContent: `$${this.lossUSD} (${this.lossMWh} MWh)`,
            bodyPercent: this.lossPct,
            footerContent: `Since ${this.startTs} `,
            popUpData: {
              modelType: this.lossesGains?.loss_model_type,
              data_start_ts: this.lossesGains?.loss_data_start_ts,
              data_end_ts: this.lossesGains?.loss_data_end_ts,
              loss_period_total_energy_mwh: `${roundToString(
                this.lossesGains?.loss_period_total_energy_mwh,
                -1,
              )} MWh`,
              type: "Loss",
            },
          },
        ];
        // Get all legacy evidence figures/data for this issue
        if (this.issueData.evidence && this.issueData.evidence.length > 0) {
          const promises = [];

          for (let i = 0; i < this.issueData.evidence.length; i++) {
            promises.push(
              this.generateEvidenceData(this.issueData.evidence[i]),
            );
          }

          this.generateEvidenceList(promises);
        }
        // Populate remaining widget cards depending on issue status
        if (
          Object.keys(this.latestStatus)?.length > 0 &&
          (this.latestStatus.definition_name_external === "Resolved" ||
            this.latestStatus.definition_name_external === "Fixed")
        ) {
          widgetCards.push(
            {
              headerContent: "Cumulative gains",
              bodyContent: `$${this.gainUSD} (${this.gainMWh} MWh)`,
              bodyPercent: this.gainPct,
              footerContent: this.effectiveDate,
              popUpData: {
                modelType: this.lossesGains?.gain_model_type
                  ? this.lossesGains.gain_model_type
                  : `${this.lossesGains.loss_model_type}*`,
                data_start_ts: this.lossesGains?.gain_data_start_ts,
                data_end_ts: this.lossesGains?.gain_data_end_ts,
                gain_period_total_energy_mwh: `${roundToString(
                  this.lossesGains?.gain_period_total_energy_mwh,
                  -1,
                )} MWh/yr`,
                type: "Gain",
                footNote: this.lossesGains?.gain_model_type
                  ? null
                  : "*No gain model has been trained, so the loss model is used to estimate gains.",
              },
            },
            {
              headerContent: "AEP gain",
              bodyContent: `$` + this.aepGainUSD + `/yr`,
              bodyPercent: this.aepGainPct,
              footerContent: this.aepGainMWh + ` MWh/yr`,
              popUpData: {
                modelType: this.lossesGains?.gain_model_type
                  ? this.lossesGains.gain_model_type
                  : `${this.lossesGains.loss_model_type}*`,
                aep_data_start_ts: this.lossesGains?.aep_data_start_ts,
                aep_data_end_ts: this.lossesGains?.aep_data_end_ts,
                aep_mwhpyr: `${roundToString(
                  this.lossesGains?.aep_mwhpyr,
                  -1,
                )} MWh/yr`,
                type: "Gain",
                footNote: this.lossesGains?.gain_model_type
                  ? null
                  : "*No gain model has been trained, so the loss model is used to estimate gains.",
              },
            },
          );
        } else {
          widgetCards.push({
            headerContent: "AEP potential",
            bodyContent: `$` + this.aepLossUSD + `/yr`,
            bodyPercent: this.aepLossPct,
            footerContent: this.aepLossMWh + ` MWh/yr`,
            popUpData: {
              modelType: this.lossesGains?.loss_model_type,
              aep_data_start_ts: this.lossesGains?.aep_data_start_ts,
              aep_data_end_ts: this.lossesGains?.aep_data_end_ts,
              aep_mwhpyr: `${roundToString(
                this.lossesGains?.aep_mwhpyr,
                -1,
              )} MWh/yr`,
              type: "Loss",
            },
          });
        }

        this.widgetCards = [...widgetCards];
      }
    },
    handleHelpClick() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        ...this.issueData.definition,
      });
    },
    async generateEvidenceData(data) {
      let plotlyData = null;

      if (data.file_type.includes("plotly")) {
        const res = await getPlotlyData(data.uri);

        if (res.status === "success") {
          plotlyData = res.data;
        }
      }

      return {
        id: data.id,
        name: data.name,
        title: data.title || "N/A",
        description: data.description || "N/A",
        uri: data.uri || "",
        type: data.file_type,
        internal: data.internal,
        plotlyData,
      };
    },
    async generateEvidenceList(evidencePromises) {
      try {
        this.evidenceLoading = true;
        this.evidences = await Promise.all(evidencePromises);
      } catch (error) {
        console.error(error);
        this.evidenceLoading = false;
      }
    },
    updateStatusHistory() {
      const issueId = this.$route.params.issueId;

      this.getIssueStatusData({ issueId });
    },
    refreshComments() {
      const issueId = this.$route.params.issueId;

      this.getComments({ issueId });
    },
    editTurbineIssueDetails() {
      this.editIssueDetails = true;
      this.issueDetailsTitle = "Editing details";
    },
    sendIssueDetailsEdit(detailsText) {
      if (this.issueData.id && detailsText) {
        let editObj = {
          id: +this.issueData.id,
          details: detailsText,
        };

        this.updateIssueDetails(editObj);
        this.editIssueDetails = false;
        this.issueDetailsTitle = "Details";
      }
    },
    cancelEditIssueDetails() {
      this.openedPanel = null;
      this.isOpen = false;
      this.editIssueDetails = false;
      this.issueDetailsTitle = "Details";
    },
    editEvidence() {
      if (this.evidenceIndex || this.evidenceIndex === 0) {
        this.isEditEvidence = !this.isEditEvidence;
      }
    },
    async saveEditedEvidence(evidenceObj) {
      if (this.initialEvidences.length > 0 && evidenceObj) {
        const issueId = this.issueData?.id;
        await this.editIssueEvidence(evidenceObj);
        await this.fetchIssueDetailData({ issueId });
        this.isEditEvidence = false;
      }
    },
    cancelEditEvidence() {
      this.isEditEvidence = false;
    },
    updateTitle(updateObj) {
      this.title = updateObj.title;
      this.evidenceIndex = updateObj.currentIndex;
    },
    updateIndex(value) {
      this.evidenceIndex = value;
    },
    async handleStatusSelected(statusObj) {
      const turbineId = this.issueData?.turbine_id;

      if (
        statusObj.definition_name_external === "Superseded" ||
        statusObj.name_external === "Superseded"
      ) {
        await this.getConfirmedIssues({ turbineId });
      }
    },
    handleChangeCurrentSwitch(event) {
      const url = new URL(window.location.href);
      let params = {
        current: "hide",
        snapshot: "show",
        index: this.evidenceIndex,
        evidenceArray: this.combinedEvidences,
      };
      if (event) {
        url.searchParams.set("evidence_view", "current");
        params.current = "show";
        params.snapshot = "hide";
      } else {
        url.searchParams.set("evidence_view", "snapshot");
      }
      this.setStaticCombinedEvidence(params);

      history.replaceState(null, null, url);
    },
  },
  async mounted() {
    const issueId = this.$route.params.issueId;
    this.lossesGainsLoading = true;
    if (
      this.issueData?.id != issueId ||
      !this.issueData ||
      Object.keys(this.issueData).length === 0
    ) {
      this.fetchIssueDetailData({ issueId });
      await Promise.all([
        this.getIssueStatusData({ issueId }),
        this.getLossesGains({ issueId }),
      ]);
      this.getComments({ issueId });
    }
    this.lossesGainsLoading = false;
  },
  watch: {
    evidences: {
      async handler(data) {
        const turbineIssueId = parseInt(this.$route.params.issueId);
        if (data.length > 0) {
          const objToSend = {
            turbineIssueId,
            evidences: this.evidences,
          };
          await this.getCurrentEvidence(objToSend);
          this.evidenceLoading = false;
        }
      },
    },
    evidenceIndex: {
      handler(data) {
        if (data >= 0) {
          if (this.initialEvidences[data]?.current?.show) {
            this.showCurrentEvidence = true;
          } else if (this.initialEvidences[data]?.snapshot?.show) {
            this.showCurrentEvidence = false;
          }
        }
      },
    },
    issueData: {
      immediate: true,
      handler(data) {
        if (data) {
          if (Object.keys(data)?.length > 0) {
            this.setDataByUrl("before_mount");
          }
        }
      },
    },
    okToSetWidgetCards: {
      immediate: true,
      handler(data) {
        if (data) {
          this.setWidgetCards();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.spinner-container {
  height: 60px;
}

.new-issue-detail-page-btn {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  button a {
    border: 1.75px solid var(--v-inverse-base);
    border-radius: 3rem;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: transparent;
    color: var(--v-text-base);
    cursor: pointer;
    text-decoration: none;
  }
  button a:hover {
    background-color: var(--v-hover-base);
    text-decoration: none;
  }
}

.col {
  display: flex;
  justify-content: center;
}
.issue-detail {
  &__header {
    &__help {
      background-color: var(--v-search-base);
      border: 1px solid var(--v-black9-base);
      color: var(--v-black3-base);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 6px 16px 6px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
    }

    &__badge {
      background: $blue4;
      border-radius: 16px;
      font-size: 12px;
      line-height: 14px;
      padding: 4px 8px;
    }
  }

  .title {
    color: var(--v-black3-base);
    font-family: "Museo Sans Rounded" !important;
    font-weight: 600;
    font-size: 1.125rem !important;
  }

  .no-data {
    height: 14rem;
    font-size: 1.5rem;
    color: var(--v-black6-base);
  }

  .edit-details__icon {
    margin-top: 2px;
  }

  .evidence-filter {
    max-width: 9rem;
  }

  .gray-title {
    color: var(--v-black4-base);
  }
}

.v-dialog > .v-card > .v-card__title .modal-label {
  font-size: 1rem;
}

::v-deep {
  .superseded-issues {
    .v-list {
      min-height: 10px;
    }
    .v-list-item {
      min-height: 5px;
      height: 1.5rem;
      padding-bottom: 0;
    }
    .v-list-item:hover {
      background-color: transparent;
    }
    .v-list-item p {
      color: var(--v-black4-base);
    }
    .v-list-item p:hover {
      background-color: transparent;
      cursor: pointer;
      color: rgba(50, 93, 171);
      text-decoration: underline;
    }
    .v-list-item__content {
      padding: 0;
    }
  }
  .superseded-issues-title {
    color: var(--v-black4-base);
    margin-bottom: 0.5rem;
  }
}

.info-icon:hover {
  color: var(--v-primary-base);
}

::v-deep {
  .evidence-filter {
    .v-input__slot {
      padding: 0 !important;
    }
  }
}
.evidence-index {
  font-weight: 400;
}

.edit-evidence__icon {
  margin-left: auto;
}

.widget-col:first-child {
  padding-left: 0;
}

@media (max-width: 1150px) {
  .third-card-no-padding {
    padding-left: 0;
  }
}
</style>

<style lang="scss">
.v-application .detail-markdown p {
  margin-bottom: 12px;
}

.v-carousel__item .v-responsive__content {
  display: flex;
  align-items: center;
}
.chevron {
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: inline-block;
  transform: scale(1.3);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.v-expansion-panel-header {
  padding: 16px;
  .card-title--black3 {
    margin: 0;
  }
}
</style>
