<template>
  <v-card class="comment-card">
    <v-card-title>
      <div class="d-flex justify-space-between comment-card__title w-100">
        <div class="comment-card__title__data d-flex">
          <div
            class="comment-card__title__data__name font-weight-light-bold mr-2"
          >
            {{ data.name }}
          </div>
          <div class="comment-card__title__data__date">
            commented on {{ commentDate }}
          </div>
        </div>
      </div>
    </v-card-title>
    <v-card-text class="comment-card__text" data-cy="comment-card">
      <vue-markdown :breaks="false" :anchor-attributes="anchorAttrs">{{
        data.comment
      }}</vue-markdown>
    </v-card-text>
    <div
      v-if="data.attachments.length > 0"
      class="d-flex flex-wrap align-center subtitle-2 pb-2 px-2"
    >
      {{ `Attachment${data.attachments.length > 1 ? "s" : ""}:` }}
      <div v-for="(attachment, index) in data.attachments" :key="index">
        <div class="pointer align-center">
          <a
            class="ml-1 d-flex text-no-wrap"
            :href="attachment.uri"
            @click.prevent="previewAttachment(attachment)"
            >{{ attachment.name
            }}<span v-if="index + 1 < data.attachments.length">, </span></a
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueMarkdown from "vue-markdown-v2";
import dayjs from "dayjs";

/**
 * Display card for user comments
 */
export default {
  name: "CommentCard",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      /**
       * Holds the attributes to be added to the `vue-markdown` link
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
    };
  },
  props: {
    /**
     * Passed from `EditWidget` containing the comment data to be displayed
     */
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /**
     * Return formatted date retrieved from the `data` prop
     */
    commentDate() {
      return dayjs(this.data.date).format("YYYY-MM-DD HH:mm:ss") + " (UTC)";
    },
  },
  methods: {
    previewAttachment(attachment) {
      this.$emit("previewAttachment", attachment);
    },
  },
};
</script>
<style lang="scss" scoped>
.attachment-link {
  color: var(--v-primary-base);
}
.attachment-link:hover {
  text-decoration: underline;
}
</style>
