<template>
  <v-card class="comments viewer-dialog">
    <v-card-text style="max-width: 1000px">
      <div class="mb-3 card-title--black4">Comments</div>

      <div class="comments__wrapper" ref="commentsWrapper">
        <div class="viewer-width" v-if="showViewer">
          <DocumentViewer
            :documentData="documentObj"
            :totalPages="pdfTotalPages"
            :currentPage="pdfCurrentPage"
            :docPresent="showViewer"
            @closeViewer="closeViewer"
            @pageUp="setPageUp"
            @pageDown="setPageDown"
            @goToPage="selectPage"
          >
            <PDFPage v-if="pdfPresent">
              <div id="canvas_container" class="canvas-container">
                <canvas id="the-canvas"></canvas>
              </div>
            </PDFPage>
          </DocumentViewer>
        </div>
        <div @click="closeViewer" class="backdrop" v-if="showViewer"></div>
        <div class="spinner-div" v-if="viewerLoading">
          <v-progress-circular
            :size="60"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div
          class="d-flex pb-4 comments__wrapper__item"
          v-for="(item, index) in commentsList"
          :key="index + '_comment'"
        >
          <template v-if="item.isTag">
            <div class="comment-avatar-space"></div>
            <div class="mx-3 d-flex align-center">
              <div class="mr-2">
                <div
                  v-if="item.isStaff"
                  class="admin-icon-wrapper d-flex align-center justify-center"
                >
                  <img src="/img/logo-icon.svg" class="logo" />
                </div>
                <div
                  v-else
                  class="user-icon-wrapper d-flex align-center justify-center"
                  :style="{ backgroundColor: stringToColor(item.name) }"
                >
                  {{ item.name | formatName }}
                </div>
              </div>
              <CommenterStatus :data="item" :name="item.name" />
            </div>
          </template>

          <template v-else>
            <div
              class="comment-avatar font-weight-light-bold mr-4 d-flex align-center justify-center"
              :style="{
                backgroundColor: stringToColor(item.name),
              }"
            >
              {{ item.avatar }}
            </div>
            <div class="comment-card-wrapper">
              <div
                :ref="`comment-${item.id}`"
                :style="{
                  border: getBorder(item),
                  borderRadius: getRadius(item),
                }"
              >
                <CommentCard
                  :data="item"
                  @previewAttachment="previewAttachment"
                />
              </div>
            </div>
          </template>
        </div>

        <div class="comments__wrapper__timeline"></div>
      </div>

      <div class="comments__divider"></div>

      <div class="mb-3 d-flex mt-5" v-if="userHasWriteAccess">
        <div
          class="comment-avatar font-weight-light-bold mr-4 d-flex align-center justify-center"
          :style="{ backgroundColor: userData.user_color }"
        >
          {{ userData.user_initials }}
        </div>
        <div class="comment-card-wrapper">
          <CommentEditor @postComment="postComment" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import axios from "axios";
import CommentCard from "./CommentCard";
import CommenterStatus from "./CommenterStatus";
import CommentEditor from "@/components/CommentEditor";
import DocumentViewer from "@/components/DocumentViewer";
import PDFPage from "@/components/PDFPage";
import PDFPagination from "@/helpers/pdfPagination";
import { findFileExt } from "@/helpers/functions";
import { getFirstNames, stringToColor } from "@/helpers/functions";
/**
 * Displays a timeline of issue comments/statuses and an editor to post a comment
 */
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "IssueCommentWidget",
  components: {
    CommentCard,
    CommenterStatus,
    CommentEditor,
    DocumentViewer,
    PDFPage,
  },
  emits: ["postComment"],
  props: {
    /**
     * Determines if a user has write access
     */
    userHasWriteAccess: {
      type: Boolean,
      default: false,
      required: true,
    },
    issueId: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Holds interspersed list of comments and statuses
       */
      comments: [],
      /**
       * Determines which editor tools are available on the comment editor
       */
      editorConfig: {
        toolbar: [
          ["Bold", "Underline", "Italic", "Strike"],
          ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          ["NumberedList", "BulletedList"],
          ["Image"],
        ],
        extraPlugins: ["justify"],
        removeButtons: "Subscript,Superscript",
      },
      /**
       * Function that turns a name into a color used as the background for user initials
       */
      stringToColor,
      routeHash: "",
      showViewer: false,
      viewerLoading: false,
      documentObj: {},
      pdfEvent: {},
      pdfPresent: false,
      pdfTotalPages: 0,
      pdfCurrentPage: 1,
    };
  },
  computed: {
    ...mapState({
      commentData: (state) => state.comments.comments,
      statusHistoryData: (state) => state.issueDetail.statusHistory,
      userData: (state) => state.user.userData,
      loading: (state) => state.issueDetail.loading.fetchIssueDetailData,
      spreadsheetLoading: (state) => state.documents.spreadsheetLoading,
    }),
    commentsList() {
      if (this.commentData?.length > 0 && this.statusHistoryData?.length > 0) {
        const interspersedComments = this.organizeCommentsList(
          this.commentData,
          this.statusHistoryData,
        );
        return this.setCommentsMap(interspersedComments);
      } else if (
        this.statusHistoryData?.length > 0 &&
        this.commentData?.length === 0
      ) {
        return this.setCommentsMap(this.statusHistoryData);
      } else if (
        this.commentData?.length > 0 &&
        this.statusHistoryData?.length === 0
      ) {
        return this.setCommentsMap(this.commentData);
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getComments: "comments/getComments",
      updateIsNotificationNew: "notifications/updateIsNotificationNew",
    }),
    /**
     * Map the comments array to be displayed
     */
    setCommentsMap(commentsList) {
      return commentsList.map((comment) => ({
        id: comment.id,
        attachments: comment.attachments,
        name: comment.user_name_full
          ? comment.user_name_full
          : comment.user_full_name,
        avatar: getFirstNames(
          comment.user_name_full
            ? comment.user_name_full
            : comment.user_full_name,
        ),
        date: `${dayjs(
          comment.comment_ts ? comment.comment_ts : comment.created_ts,
        )
          .utc()
          .format("DD MMM YYYY, HH:mm")} UTC`,
        comment: comment.comment ? comment.comment : "",
        statusComment: comment.comment_external ? comment.comment_external : "",
        isStaff: false,
        isTag: comment.created_ts ? true : false,
        status: comment.definition_name_external
          ? comment.definition_name_external
          : "",
      }));
    },
    /**
     * Trigger the emit function `postComment`
     */
    postComment() {
      /**
       * Tell the parent component that a new comment was posted
       */
      this.$emit("postComment");
    },
    /**
     * Combine comments and statusHistory into one array and then sort by date
     */
    organizeCommentsList(comments, history) {
      const concatenatedArray = comments.concat(history);

      const sortedArray = concatenatedArray.sort(function (a, b) {
        let c = new Date(a.comment_ts ? a.comment_ts : a.created_ts);
        let d = new Date(b.created_ts ? b.created_ts : b.comment_ts);
        return c - d;
      });

      return sortedArray;
    },
    getBorder(item) {
      const routeHash = this.$route.hash;
      if (routeHash.includes("comment")) {
        const temp = routeHash.split("#");
        const ref = temp[1];

        if (ref === `comment-${item.id}`) {
          setTimeout(() => {
            this.$refs[ref][0].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 600);
          setTimeout(() => {
            this.updateIsNotificationNew(false);
          }, 2300);

          return "2px solid #115B8A";
        }
      } else {
        return "none";
      }
    },
    getRadius(item) {
      const routeHash = this.$route.hash;

      if (routeHash.includes("comment")) {
        const temp = routeHash.split("-");
        const hashId = temp[1];

        if (+hashId === +item.id) {
          return "12px";
        }
      }
    },
    previewAttachment(attachment) {
      const fileExt = findFileExt(attachment.uri);
      const html = document.querySelector("html");
      html.style.overflow = "hidden";
      this.documentObj.name = attachment.name;
      this.documentObj.type = fileExt;
      this.documentObj.uri = attachment.uri;

      if (this.documentObj.type === "html") {
        this.viewerLoading = true;
        axios.get(this.documentObj.uri).then((response) => {
          this.documentObj.data = response.data;
          this.showViewer = true;
          this.viewerLoading = false;
        });
      } else if (this.documentObj.type === "pdf") {
        this.viewerLoading = true;
        // eslint-disable-next-line no-undef
        this.getPdf(this.documentObj.uri, pdfjsLib);
      } else {
        this.showViewer = true;
        this.viewerLoading = false;
      }
    },
    async getPdf(url, pdfjsLib) {
      try {
        const pdf = await PDFPagination.loadPdf(url, pdfjsLib);
        this.pdfTotalPages = pdf.numPages;
        this.pdfEvent = pdf;
        this.getPdfPage(1);
        this.pdfPresent = true;
        this.showViewer = true;
        this.viewerLoading = false;
      } catch (error) {
        console.error(
          "Loading pdf has encountered a problem::",
          error.toString(),
        );
      }
    },
    async getPdfPage(page) {
      try {
        await PDFPagination.loadPdfPage(page, this.pdfEvent);
        this.viewerLoading = false;
      } catch (error) {
        console.error("Failing to get and load pdf page::", error.toString());
      }
    },
    async setPageUp() {
      try {
        const page = PDFPagination.pageUp(
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(page);
      } catch (error) {
        console.error("Page up is failing:: ", error.toString());
      }
    },
    async setPageDown() {
      try {
        const page = PDFPagination.pageDown(
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(page);
      } catch (error) {
        console.error("Page down is failing:: ", error.toString());
      }
    },
    async selectPage(page) {
      try {
        const pageSelected = PDFPagination.pageSelection(
          page,
          this.pdfTotalPages,
          this.pdfCurrentPage,
        );
        this.pdfCurrentPage = page;
        await this.getPdfPage(pageSelected);
      } catch (error) {
        console.error("Select page is failing:: ", error.toString());
      }
    },
    closeViewer() {
      this.showViewer = false;
      this.pdfPresent = false;
      this.pdfCurrentPage = 1;
      const html = document.querySelector("html");
      html.style.overflow = "auto";
    },
  },
  async beforeMount() {
    const issueId = this.issueId;
    if (
      +this.commentData[0]?.turbine_issue_id !== +this.$route.params.issueId
    ) {
      await this.getComments({ issueId });
    }
  },
  mounted() {
    // Set worker src for pdf retrieval
    // eslint-disable-next-line no-undef
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";
  },
  filters: {
    formatName(val) {
      if (!val) {
        return "";
      }

      const nameArr = val.split(" ");

      return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";

.comments {
  &__wrapper {
    position: relative;

    &__item {
      z-index: 10;
    }

    &__timeline {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      top: 0;
      left: 72px;
      background-color: var(--v-border-base);
      z-index: 1;
    }
  }

  &__divider {
    border: 1px solid var(--v-black6-base);
  }

  .comment-avatar {
    color: var(--v-white1-base);
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .comment-card-wrapper {
    flex: 1;
    z-index: 2;
  }

  .comment-card-wrapper li::marker {
    list-style-type: none;
  }

  .comment-avatar-space {
    margin-right: 50px;
  }

  .admin-icon-wrapper {
    width: 24px;
    height: 24px;
    background-color: var(--v-black10-base);
    border-radius: 50%;
  }

  .commenter-name {
    color: var(--v-black3-base);
    white-space: nowrap;
  }

  .user-icon-wrapper {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--v-white1-base);
    z-index: 2;
  }

  .logo {
    width: 15px;
  }
}

.viewer-dialog {
  position: relative;
}
.viewer-width {
  width: 100%;
  z-index: 1500;
}
.canvas-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}
.spinner-div {
  position: absolute;
  z-index: 1550;
  top: 25%;
  left: 50%;
}
</style>
