<template>
  <div data-cy="comment-editor">
    <UploadDialog
      v-if="showDialog"
      @setShowDialog="toggleUploadDialog"
      :isNotSiteDocument="true"
      title="Upload attachment"
      @setFile="setFile"
    />
    <div class="editor" @keydown.ctrl.enter="handleCommentClick">
      <v-md-editor
        v-model="text"
        mode="edit"
        height="400px"
        left-toolbar="bold italic strikethrough ul ol link image"
        data-cy="textarea"
      />
    </div>
    <div class="d-flex justify-space-between mt-3">
      <div class="d-flex">
        <v-btn
          color="primary"
          class="px-4 mr-2 white--text"
          :disabled="actionLoading"
          @click="toggleUploadDialog(true)"
          >Upload attachment</v-btn
        >
        <div class="d-flex flex-wrap">
          <span
            v-for="(attachment, index) in attachments"
            :key="index"
            class="d-flex align-center pl-2 text-no-wrap"
          >
            {{ attachment.name }}
            <v-icon @click="removeAttachment(attachment)">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <v-btn v-show="text !== ''" class="mr-2" outlined @click="cancelComment"
          >Cancel</v-btn
        >
        <v-btn
          color="green"
          class="px-4 white--text"
          :disabled="text === ''"
          :loading="actionLoading"
          data-ga="issue_detail_comment_created"
          @click="handleCommentClick"
          data-cy="submit-comment"
          >Comment</v-btn
        >
      </div>
    </div>

    <div
      @click="toggleUploadDialog(false)"
      class="backdrop"
      v-if="showDialog"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UploadDialog from "@/components/UploadDialog";
/**
 * Displays a markdown editor for users to create comments on issues
 */
export default {
  name: "CommentEditor",
  components: {
    UploadDialog,
  },
  emits: ["postComment"],
  data() {
    return {
      content: "", // Doesn't seem to be used anywhere
      /**
       * Initial values for the attributes of the markdown editor
       */
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      /**
       * Holds the content of the comment being created in the editor
       */
      text: "",
      showDialog: false,
      attachments: [],
    };
  },
  computed: {
    ...mapState({
      actionLoading: (state) => state.comments.loading.addComment,
    }),
  },
  methods: {
    ...mapActions({
      addComment: "comments/addComment",
    }),
    /**
     * Triggers action to add comment to DB and comment list of the turbine issue
     */
    async handleCommentClick() {
      if (this.text) {
        await this.addComment({
          issueId: this.$route.params.issueId,
          comment: this.text,
          attachments: this.attachments,
        });
        /**
         * Emits the `postComment` event to `EditWidget`
         */
        this.$emit("postComment");
        this.text = "";
        this.attachments = [];
      }
    },
    cancelComment() {
      this.text = "";
      this.attachments = [];
    },
    toggleUploadDialog(showDialog) {
      this.showDialog = showDialog;
    },
    setFile(chosenFile) {
      this.attachments.push(...chosenFile);
    },
    removeAttachment(attachment) {
      this.attachments = this.attachments.filter((item) => item !== attachment);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables";
.comment-card {
  .v-card__title {
    padding-top: 0;
    padding-bottom: 0;
  }

  .editor-status-action {
    margin-bottom: -1px;

    &.active {
      background: var(--v-white1-base);
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid var(--v-black6-base);
      border-left: 1px solid var(--v-black6-base);
      border-right: 1px solid var(--v-black6-base);
    }
  }
}
.editor {
  display: flex;
  flex-direction: column;
  background-color: var(--v-black10-base);
  border: 1px solid var(--v-black6-base);
  border-radius: 4px;

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.preview {
    background-color: transparent;
    border: 0;
  }
}

.preview-editor {
  padding: 1.25rem 1rem;
  background-color: transparent;
}
</style>

<style>
.v-md-textarea-editor pre,
.v-md-textarea-editor textarea {
  word-break: normal;
}
.comments .default-dialog {
  bottom: 0;
  top: unset;
}
</style>
