<template>
  <v-expansion-panels :value="open">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="card-title--black3">Recommendation</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-if="
            recommendationNotes == null &&
            gotSuggestedRecommendationResponse === false
          "
          class="d-flex justify-center align-center"
        >
          <DataLoading />
        </div>
        <div
          v-if="
            recommendationNotes == null &&
            gotSuggestedRecommendationResponse === true
          "
        >
          <p>
            The exact fix for this issue is not part of WindESCo's fix library
            because there could be many root causes.
          </p>
        </div>
        <div v-if="!turbineModDef && recommendationNotes !== null">
          <p>To get started fixing the issue, we recommend the following:</p>
        </div>
        <vue-markdown
          class="recommend-markdown"
          v-if="recommendationNotes !== null"
          :breaks="false"
          :anchor-attributes="anchorAttrs"
          >{{ recommendationNotes }}</vue-markdown
        >
        <div v-if="suggestedRecommendation == null || turbineModDef == null">
          <a
            href="https://meetings.hubspot.com/marisa-kiefer/customer-schedule-with-analytics-team"
            >Book a session</a
          >
          with the WindESCo Analytics team to meet with one of our subject
          matter experts to develop a dedicated fix plan for this issue.
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import VueMarkdown from "vue-markdown-v2";
import { mapActions, mapState } from "vuex";
import DataLoading from "@/components/DataLoading";

export default {
  name: "SuggestedRecommendations",
  components: {
    VueMarkdown,
    DataLoading,
  },
  props: {
    open: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      anchorAttrs: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
      },
      gotSuggestedRecommendationResponse: false,
    };
  },
  computed: {
    ...mapState({
      suggestedRecommendation: (state) =>
        state.issueDetail.suggestedRecommendation,
    }),
    recommendationNotes() {
      if (this.suggestedRecommendation) {
        return this.suggestedRecommendation.notes;
      }
      return null;
    },
    turbineModDef() {
      if (this.suggestedRecommendation) {
        return this.suggestedRecommendation.turbine_mod_def;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      getSuggestedRecommendation: "issueDetail/getSuggestedRecommendation",
    }),
  },
  async beforeMount() {
    await this.getSuggestedRecommendation({
      issueId: this.$route.params.issueId,
    });
    this.gotSuggestedRecommendationResponse = true;
  },
};
</script>

<style lang="scss">
.recommend-markdown {
  p,
  div {
    color: var(--v-text-base);
  }
}
</style>
